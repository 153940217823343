<template>
  <b-sidebar id="add-new-membership-sidebar" :visible="isEditMembershipSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-membership-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Add codes</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- status -->
          <validation-provider #default="validationContext" name="Cant" rules="required">
            <b-form-group label="Cant" label-for="Cant">
              <b-form-input id="Cant" type="number" v-model="stateData.cant" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Cant codes" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Type" rules="required">
            <b-form-group label="Type" label-for="Type">
              <v-select id="Type" v-model="stateData.code_membership_types_id"
                :state="getValidationState(validationContext)" :options="optionsMembershipType"
                :reduce="(value) => value.id" label="name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="isPay"
            rules="required"
          >
            <b-form-group label="Is pay?" label-for="isPay">
              <v-select
                id="isPay"
                v-model="stateData.is_pay"
                :state="getValidationState(validationContext)"
                :options="optionsIsPay"
                label="text"
                :reduce="(value) => value.value"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="loading">
              <span v-if="!loading">Add</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-danger" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ref, onMounted } from "@vue/composition-api";

import axiosCV from "@/services/admin/membership/lote";
import axiosML from "@/services/admin/membership/lote";
import axiosMT from "@/services/admin/membership/type";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditMembershipSidebarActive",
    event: "update:is-edit-membership-sidebar-active",
  },
  props: {
    isEditMembershipSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const optionsIsPay = ref([
      { text: "Pay", value: true },
      { text: "Unpaid", value: false },
    ]);
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit)));
    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit));
    };

    const optionsMembershipType = ref([]);
    const optionsCodeLote = ref([]);
    onMounted(() => {
      getOptions();
    });
    const getOptions = () => {
      axiosML.membershipLoteList(10).then(({ registro: { data } }) => {
        optionsCodeLote.value = data;
      });

      axiosMT.membershipTypeList(10).then(({ registro: { data } }) => {
        optionsMembershipType.value = data;
      });
    };

    const loading = ref(false);
    const onSubmit = () => {
      // por defecto, auto generate en true
      loading.value = true;
      stateData.value.auto_generate = true;
      const MembershipItem = JSON.parse(JSON.stringify(stateData))
      return loading.value = false;
      axiosCV
        .membershipLoteUpdate(MembershipItem.value.id, MembershipItem.value)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editMembershipSuccess", true);
        })
        .catch(() => {
          emit("editMembershipSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      stateData,
      loading,
      optionsStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      optionsMembershipType,
      optionsCodeLote,
      optionsIsPay
    };
  },
};
</script>

<style></style>
import { userAxios } from '@/services'

const membershipLoteList = async (perPage) => {
    try {
        return await userAxios.get(`code-membership-lote/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipLoteCreate = async (data) => {
    try {
        return await userAxios.post('code-membership-lote', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipLoteUpdate = async (id, data) => {
    try {
        return await userAxios.put(`code-membership-lote/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipLoteDelete = async (id) => {
    try {
        return await userAxios.delete(`code-membership-lote/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipLotePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`code-membership-lote/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipDetails = async (id, page, perPage) => {
    try {
        return await userAxios.get(`code-membership-lote/detail/${id}/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipLoteFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`code-membership-lote/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipLoteFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`code-membership-lote/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    membershipLoteList,
    membershipLoteCreate,
    membershipLoteUpdate,
    membershipLoteDelete,
    membershipLotePagination,
    membershipLoteFilter,
    membershipLoteFilterPagination,
    membershipDetails
}
<template>
  <b-modal id="add-new-membership-sidebar" :visible="isEditMembershipSidebarActive" bg-variant="white" size="xl" shadow
    backdrop no-header hide-footer hide-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-membership-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <div>
          <h6>
            Lote: {{ itemEdit.lote }}
          </h6>
          <h6>
            Creator: {{ detailInfo.creator }}
          </h6>
          <h6>
            Total: {{ detailInfo.quantity }}
          </h6>
          <h6>
            Available: {{ detailInfo.avaible }}
          </h6>
          <h6>
            Used: {{ detailInfo.used }}
          </h6>
          <h6>
            Description:
            {{ detailInfo.description }}
          </h6>
        </div>
      </div>

      <!-- BODY -->
      <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
        show-empty empty-text="No matching records found">
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.status ? $t("Active") : $t("Inactive") }}</span>
          </div>
        </template>
        <template #cell(is_pay)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.is_pay ? "yes" : "Not pay" }}</span>
          </div>
        </template>
        <template #cell(membership)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.memberships ? data.item.memberships.name : ""
              }}</span>
          </div>
        </template>
        <template #cell(user)="data">
          <b-link :to="{
            name: 'patients-view',
            params: { id: data.item.user.id },
          }" v-if="data.item.user">
            <span>{{ data.item.user.name }} {{ data.item.user.lastname }}</span>
          </b-link>
          <span v-else>Not assigned yet</span>
        </template>
        <!-- <template #cell(actions)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Edit'" v-if="$can('update', 'module_modules.type_of_modules.memberships.lotes')"
            @click="editMembership(data.item)">
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Details'" @click="itemEdit = data.item; isLoteDetails = true">
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Add Codes'"
            v-if="$can('update', 'module_modules.type_of_modules.memberships.lotes')"
            @click="itemEdit = data.item; isAddCodes = true">
            <feather-icon icon="PlusIcon" />
          </b-button>

          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon"
            v-b-tooltip.hover.top="'Delete'" v-if="$can('destroy', 'module_modules.type_of_modules.memberships.lotes')"
            @click="deleteMembership(data.item)">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template> -->
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BLink,
  BTable
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";

import axiosCV from "@/services/admin/membership/lote";

export default {
  components: {
    BTable,
    BModal,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    PaginationTable,
    SpinnerLoading,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditMembershipSidebarActive",
    event: "update:is-edit-membership-sidebar-active",
  },
  props: {
    isEditMembershipSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const items = ref([])
    const dataMeta = ref({ from: 1, to: 1, of: 1 })
    const detailInfo = ref({
      quantity: "",
      used: "",
      avaible: "",
      creator: "",
      description: "",
      membership: "",
      Status: "",
    })
    const totalUsers = ref(0)
    const perPage = ref(10)
    const currentPage = ref({ page: 1 })
    const perPageOptions = ref([10, 25, 50, 100])
    const fields = [
      "id",
      // { key: "code", label: "code" },
      { key: "is_pay", label: "is pay" },
      { key: "user", label: "Patient" },
      { key: "membership", label: "membership" },
      { key: "status", label: "Status" },
    ]
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit)));
    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit));
    };

    const getData = () => {
      axiosCV.membershipDetails(stateData.value.id, 1, 1).then(({ registro: { data: { data, total, current_page, from, to }, detail } }) => {
        items.value = data;
        detailInfo.value = detail;
        totalUsers.value = total;
        dataMeta.value.of = total;
        dataMeta.value.from = from;
        dataMeta.value.to = to;
        currentPage.value.page = current_page;
      });
    }
    getData()
    const loading = ref(false);
    function pageChanged(page) {
      axiosCV.membershipDetails(stateData.value.id, page, currentPage.value.page)
        .then(({ registro: { data: { data, total, current_page, from, to }, detail } }) => {
          items.value = data;
          detailInfo.value = detail;
          totalUsers.value = total;
          dataMeta.value.of = total;
          dataMeta.value.from = from;
          dataMeta.value.to = to;
          currentPage.value.page = current_page;
        });
    }
    const onSubmit = () => {
      loading.value = true;
      const MembershipItem = JSON.parse(JSON.stringify(stateData))
      axiosCV
        .membershipLoteUpdate(MembershipItem.value.id, MembershipItem.value)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editMembershipSuccess", true);
        })
        .catch(() => {
          emit("editMembershipSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      stateData,
      loading,
      optionsStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      items,
      fields,
      dataMeta,
      totalUsers,
      perPage,
      detailInfo,
      currentPage,
      perPageOptions,
      pageChanged
    };
  },
};
</script>

<style></style>
<template>
  <b-sidebar id="add-new-membership-sidebar" :visible="isAddNewMembershipSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-membership-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t("Add") }} {{ $t("New") }} Lote</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- AutoGenrate -->
          <validation-provider #default="validationContext" name="AutoGenrate" rules="required">
            <b-form-group label="Auto Genrate" label-for="AutoGenrate">
              <v-select id="AutoGenrate" v-model="stateData.auto_gen" :state="getValidationState(validationContext)"
                :options="optionsAutoGenerate" label="text" :reduce="(value) => value.value"
                @option:selected="changeAutoGen($event)" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Cant -->
          <validation-provider v-if="multipleCodes" #default="validationContext" name="Cant" rules="required">
            <b-form-group label="Cant" label-for="Cant">
              <b-form-input id="Cant" type="number" v-model="stateData.cant" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Cant codes" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- File -->
          <validation-provider v-if="!multipleCodes" #default="validationContext" name="File"
            :rules="multipleCodes ? '' : 'required'">
            <b-form-group label="File" label-for="file">
              <b-form-file id="file" v-model="stateData.file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" autofocus
                :state="getValidationState(validationContext)" placeholder="File" @change="onChangeFile($event)" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Membership -->
          <validation-provider #default="validationContext" name="Membership">
            <b-form-group label="Membership" label-for="Membership">
              <v-select id="Membership" v-model="stateData.memberships_id"
                :state="getValidationState(validationContext)" :options="optionsMembership"
                :reduce="(value) => value.id" label="name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Lote -->
          <!-- <validation-provider
            #default="validationContext"
            name="Lote"
            rules="required"
          >
            <b-form-group label="Lote" label-for="Lote">
              <v-select
                id="Lote"
                v-model="stateData.code_lotes_id"
                :state="getValidationState(validationContext)"
                :options="optionsCodeLote"
                :reduce="(value) => value.id"
                label="lote"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Type -->
          <validation-provider #default="validationContext" name="Type" rules="required">
            <b-form-group label="Type" label-for="Type">
              <v-select id="Type" v-model="stateData.code_membership_types_id"
                :state="getValidationState(validationContext)" :options="optionsMembershipType"
                :reduce="(value) => value.id" label="name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Expiration date -->
          <validation-provider #default="validationContext" name="expiration_date" rules="required">
            <b-form-group label="Expiration date" label-for="expiration date">
              <flat-pickr v-model="stateData.expiration_date" :state="getValidationState(validationContext)"
                :config="config" class="form-control" placeholder="MM-DD-YYYY" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- isPay -->
          <validation-provider
            v-if="multipleCodes"
            #default="validationContext"
            name="isPay"
            rules="required"
          >
            <b-form-group label="Is pay?" label-for="isPay">
              <v-select
                id="isPay"
                v-model="stateData.is_pay"
                :state="getValidationState(validationContext)"
                :options="optionsIsPay"
                label="text"
                :reduce="(value) => value.value"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Description" rules="required">
            <b-form-group label="Description" label-for="Description">
              <b-form-textarea id="Description" v-model="stateData.description" autofocus
                :state="getValidationState(validationContext)" placeholder="Description" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="loading">
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-danger" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BFormTextarea
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { notificationCheck } from "@/mixins/NotificationSetup";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";

import axiosM from "@/services/admin/membership";
import axiosMC from "@/services/admin/membership/code";
import axiosML from "@/services/admin/membership/lote";
import axiosMT from "@/services/admin/membership/type";
import TreatmentProcedure from "@/views/office-visit/office-visit-review/procedure/TreatmentProcedure.vue";

export default {
  components: {
    BSidebar,
    flatPickr,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    SpinnerLoading,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewMembershipSidebarActive",
    event: "update:is-add-new-membership-sidebar-active",
  },
  props: {
    isAddNewMembershipSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const config = {
      dateFormat: "m-d-Y",
      allowInput: true,
      static: true,
      enableTime: false,
    }
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const optionsAutoGenerate = ref([
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ]);
    const optionsIsPay = ref([
      { text: "Pay", value: true },
      { text: "Unpaid", value: false },
    ]);
    const stateDataTem = {
      file: null,
      memberships_id: null,
      code_lotes_id: null,
      code_membership_types_id: null,
      description: "",
      cant: null,
      auto_gen: true,
      is_pay: false,
    };
    const fileBase64 = ref(null);
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    onMounted(() => {
      getOptions();
    });

    const optionsMembership = ref([]);
    const optionsCodeLote = ref([]);
    const optionsMembershipType = ref([]);

    const multipleCodes = ref(true);

    const getOptions = () => {
      axiosM
        .membershipList(10)
        .then(({ data }) => {
          optionsMembership.value = data;
        })
        .catch(() => {
          optionsMembership.value = [];
        });

      axiosML.membershipLoteList(10).then(({ registro: { data } }) => {
        optionsCodeLote.value = data;
      });

      axiosMT.membershipTypeList(10).then(({ registro: { data } }) => {
        optionsMembershipType.value = data;
      });
    };

    const formData = new FormData();
    const onChangeFile = (event) => {
      const fileResource = event.target.files[0];
      formData.append("file", fileResource);
      /* const reader = new FileReader();
      reader.readAsDataURL(fileResource);
      reader.onload = () => {
        console.log(reader.result);
        fileBase64.value = reader.result;
      }; */
    };

    const changeAutoGen = (event) => {
      multipleCodes.value = event.value;
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const datos = {
        ...stateData.value,
        file: fileBase64.value,
      };
      formData.append("expiration_date", datos.expiration_date);
      formData.append("description", datos.description);
      formData.append("memberships_id", datos.memberships_id);
      formData.append("code_lotes_id", datos.code_lotes_id);
      formData.append("code_membership_types_id", datos.code_membership_types_id);
      formData.append("cant", datos.cant);
      formData.append("auto_gen", datos.auto_gen);
      formData.append("is_pay", datos.is_pay);
      axiosMC
        .membershipCodeCreate(formData)
        .then((res) => {
          loading.value = false;
          resetuserData();
          const { type, message } = notificationCheck(res);
          emit("createMembership", { registro: { type, message } });
        })
        .catch(() => {
          loading.value = false;
          emit("createMembership", {
            registro: { type: "danger", message: "Membership type not added" },
          });
        });
    };
    return {
      loading,
      stateData,
      optionsMembership,
      optionsCodeLote,
      optionsMembershipType,
      onChangeFile,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      optionsAutoGenerate,
      changeAutoGen,
      multipleCodes,
      optionsIsPay,
      config
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.flatpickr-wrapper {
  // width: 50% !important;
  display: block;
}
</style>

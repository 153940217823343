<template>
  <div>
    <LoteMembershipFilter v-if="$can('filter', 'module_modules.type_of_modules.memberships.lotes')" :loading="loading"
      @searchFilter="searchFilter" @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="Lote Membership" iconTable="GitBranchIcon" />
      <div class="m-1">
        <audio ref="eggAudio" src="./egg.mp3" />
        <LoteMembershipEdit v-if="isEditMembershipSidebarActive"
          :is-edit-membership-sidebar-active.sync="isEditMembershipSidebarActive" :itemEdit="itemEdit"
          @editMembershipSuccess="editMembershipSuccess" />
        <loteDetails v-if="isLoteDetails" :is-edit-membership-sidebar-active.sync="isLoteDetails"
          :itemEdit="itemEdit" />
        <AddCodes v-if="isAddCodes" :is-edit-membership-sidebar-active.sync="isAddCodes" :itemEdit="itemEdit" />
        <LoteMembershipAdd :is-add-new-membership-sidebar-active.sync="isAddNewMembershipSidebarActive"
          @createMembership="createMembership" />
        <CodeMembershipAdd :is-add-new-membership-sidebar-active.sync="isAddNewCodeSidebarActive"
          @createMembership="createCode" />
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Show") }}</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button v-if="$can('store', 'module_modules.type_of_modules.memberships.lotes')" variant="primary"
                @click="isAddNewCodeSidebarActive = true" :disabled="loading">
                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} New Lote</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
        show-empty empty-text="No matching records found">
        <template #cell(membership)="data">
          <div class="text-nowrap" v-for="(membership, index) in data.item.membership" :key="index">
            <b-badge variant="primary">
              {{ membership.name }}
            </b-badge>
          </div>
        </template>
        <template #cell(lote)="data">
          <div class="text-nowrap">
            <span
              @click="eggAddCodes($event, $can('update', 'module_modules.type_of_modules.memberships.lotes'), data.item)">
              {{ data.item.lote }}
            </span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
      data.item.status ? $t("Active") : $t("Inactive")
    }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Edit'" v-if="$can('update', 'module_modules.type_of_modules.memberships.lotes')"
            @click="editMembership(data.item)">
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Details'" @click="itemEdit = data.item; isLoteDetails = true">
            <feather-icon icon="EyeIcon" />
          </b-button>
          <!-- <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Add Codes'"
            v-if="$can('update', 'module_modules.type_of_modules.memberships.lotes')"
            @click="itemEdit = data.item; isAddCodes = true">
            <feather-icon icon="PlusIcon" />
          </b-button> -->

          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon"
            v-b-tooltip.hover.top="'Delete'" v-if="$can('destroy', 'module_modules.type_of_modules.memberships.lotes')"
            @click="deleteMembership(data.item)">
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import axiosCV from "@/services/admin/membership/lote";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import { notificationCheck } from "@/mixins/NotificationSetup";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import LoteMembershipEdit from "./LoteMembershipEdit.vue";
import LoteMembershipAdd from "./LoteMembershipAdd.vue";
import LoteMembershipFilter from "./LoteMembershipFilter.vue";
import AddCodes from "./AddCodes.vue";
import loteDetails from "./loteDetails.vue";
import { ref } from '@vue/composition-api'
import CodeMembershipAdd from "../code/CodeMembershipAdd.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    LoteMembershipEdit,
    CodeMembershipAdd,
    LoteMembershipAdd,
    LoteMembershipFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    vSelect,
    AddCodes,
    loteDetails
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        "id",
        { key: "lote", label: "Lote" },
        { key: "code_memberships_count", label: "quantity" },
        { key: "description", label: "description" },
        { key: "membership", label: "membership" },
        { key: "date", label: "Expiration date" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      isEditMembershipSidebarActive: false,
      isAddNewMembershipSidebarActive: false,
      isAddNewCodeSidebarActive: false,
      isLoteDetails: false,
      isAddCodes: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      membershipFilter: null,
    };
  },
  mounted() {
    this.getMembership();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getMembership();
      } else {
        this.searchFilter(this.membershipFilter);
      }
    },
  },
  methods: {
    eggAddCodes(event, canIPass, item) {
      if (!canIPass) return
      if (event.ctrlKey && event.altKey) {
        this.$refs.eggAudio.play()
        document.body.classList.add("r2d2")
        this.itemEdit = item
        this.isAddCodes = true
      }
    },
    createCode({ registro }) {
      const { type, message } = registro;
      this.$refs.toast[type](message);
      this.isAddNewCodeSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    getMembership() {
      axiosCV
        .membershipLoteList(this.perPage)
        .then(
          ({ registro: { data, current_page, total, per_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          }
        );
    },
    editMembership(item) {
      this.isEditMembershipSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editMembershipSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Membership updated successfully");
      } else {
        this.$refs.toast.error("Error updating type membership");
      }
      this.isEditMembershipSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createMembership({ registro }) {
      const { type, message } = registro;
      this.$refs.toast[type](message);
      this.isAddNewMembershipSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    deleteMembership(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosCV.membershipLoteDelete(item.id).then((res) => {
            const { type, message } = notificationCheck(res);
            this.$refs.toast[type](message);
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV
          .membershipLotePagination(this.perPage, page)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosCV
          .membershipLoteFilterPagination(
            this.perPage,
            page,
            this.membershipFilter
          )
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.membershipFilter = value;
        axiosCV
          .membershipLoteFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getMembership();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style>
.r2d2 {
  cursor: url(https://cdn.custom-cursor.com/db/9644/32/star-wars-r2-d2-cursor.png), default !important;
}
</style>

import { userAxios } from '@/services'

const membershipCodeList = async (perPage) => {
    try {
        return await userAxios.get(`code-membership/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipCodeCreate = async (data) => {
    try {
        const currentContentType = userAxios.defaults.headers.common['Content-Type'];
        userAxios.defaults.headers.common['Content-Type'] = 'multipart/form-data';

        const response = await userAxios.post('code-membership', data);
        const responseData = response.data;
        
        userAxios.defaults.headers.common['Content-Type'] = currentContentType;
        return responseData
    } catch (error) {
        console.log(error);
    }
}

const membershipCodeUpdate = async (id, data) => {
    try {
        return await userAxios.put(`code-membership/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipCodeDelete = async (id) => {
    try {
        return await userAxios.delete(`code-membership/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipCodePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`code-membership/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipCodeFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`code-membership/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipCodeFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`code-membership/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    membershipCodeList,
    membershipCodeCreate,
    membershipCodeUpdate,
    membershipCodeDelete,
    membershipCodePagination,
    membershipCodeFilter,
    membershipCodeFilterPagination
}